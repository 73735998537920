<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
Number.prototype.map = function (inMin, inMax, outMin, outMax) {
  return ((this - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
};

export default {
  name: 'WebSynth',
  data() {
    return {};
  },
};
</script>


<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}
</style>
